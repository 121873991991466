<template>
    <main class="pt-12 grey full-height">
        <v-row dense  class="mx-4">
            <v-col :offset="offset" :cols="num_rows">
                <v-card :loading="loading" :class="centraliza" width="400">
                    <v-card-title>
                        <v-row class="text-center">
                            <v-col>
                                AUTO ATENDIMENTO
                            </v-col>
                        </v-row>
                </v-card-title>
                <v-card-text class="pa-4">
                    <v-card width="100%" class="ma-auto pb-4">
                        <v-row class="text-center ma-2 pt-4">
                            <v-col cols="12">
                                <v-text-field 
                                class="centered-text" 
                                label="DIGITE O SEU CPF" 
                                outlined 
                                :value="cpf"
                                placeholder="000.000.000-00" 
                                maxlength="14"
                                hide-details 
                                :rules="[cpfRule]" 
                                required
                                :append-icon="showClearIcon ? 'mdi-close-circle' : ''"
                                @click:append="handleClearIconClick"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="text-center" dense>
                            <v-col v-for="digit in digits" :key="digit" cols="4" 
                                class="mx-0 px-0 digit">
                                <v-btn large @click="handleButtonClick(digit)">{{ digit }}</v-btn>
                            </v-col>
                            <v-col class="mx-0 px-0">
                                <v-btn 
                                    color="blue" 
                                    large
                                    outlined
                                    :disabled="!btn_cpfRule" 
                                    @click="getAgendamentos()"
                                    :loading="loading"
                                >
                                <v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                            <v-col class="mx-0 px-0">
                                <v-btn 
                                    color="orange" 
                                    large
                                    outlined
                                    :disabled="!btn_backspace" 
                                    @click="handleBackspace()"
                                >
                                <v-icon>mdi-backspace</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card-text>
            </v-card>
                <!-- <pre>{{ agendamentos[0] }}</pre> -->
            </v-col>
            <v-col class="ma-0 pa-0">
                <v-card class="mx-auto my-2" width="90%" v-for="(i,k) in agendamentos" :key="k">
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="9" class="imprimir-conteudo" :id="`minhaDiv${i.cd_it_agenda_central}`">
                                <v-row dense>
                                    <v-col>

                                        PACIENTE: <b>{{ i.nm_paciente }}</b><br>
                                        TIPO: <b>{{ i.tipo_agenda  }}</b><br>
                                        AGENDADO PARA: <b>{{ i.hr_agenda_formatada }}</b><br>
                                        ID Agendamento: <b>{{ i.cd_it_agenda_central }}</b>  
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col>
                                        <img :src="i.qrcodeData" alt="QR Code">
                                    </v-col>
                                    <v-col v-if="senha_gerada" class="text-center d-flex align-center">
                                        <span >Senha:&nbsp;</span> 
                                        <span class="ft40">{{ senha_gerada[0].ds_senha }}</span><br>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="text-center d-flex align-center">
                                <v-btn v-if="show_senha" outlined color="blue" @click="imprimirDiv(i.cd_it_agenda_central)">
                                    Imprimir <v-icon class="ml-2">mdi-printer</v-icon>
                                </v-btn>
                                <v-btn v-else outlined :loading="loading_gera_senha" color="green" @click="gerarSenha(i)">
                                    Gerar senha <v-icon class="ml-2">mdi-ticket-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </main>
</template>
  
<script>
import AppEtlService from '@/service/AppEtlService'
//import VueQrcode from 'vue-qrcode';
import QRCode from 'qrcode';

const api = new AppEtlService();
export default {
    name: 'Totem',
    components: {
    },
    data: () => ({
        digits: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
        loading: false,
        loading_gera_senha: false,
        show_senha: false,
        agendamentos: [],
        senha_gerada: null,
        cpf: '831.417.153-00',
        tmp: '',
        showClearIcon: false,  
    }),
    methods: { 
        onDataUrlChange(dataUrl) {
            this.dataUrl = dataUrl
        },
        getAgendamentos() {
            this.loading = true
            let cpf = this.cpf.replace(/[.-]/g, '');
            api.get_query('get_agend_pac_vm', [cpf])
            .then(res => {
                this.agendamentos = res.data.map(e=>{
                    QRCode.toDataURL('agendamento '+e.cd_it_agenda_central).then((url) => {
                        e.qrcodeData = url
                    })
                    .catch((error) => {
                        console.error('Erro ao gerar o QR code:', error);
                    });
                    return e
                })
            })
            .catch(err => {
                return err.response
            })
            .finally(()=>this.loading=false)
        },
        getSenha(id) {
            this.loading_gera_senha=true
            const param = Object.values(id)
            api.get_query('get_senha_tri', [param[1]])
            .then(res => {
                this.senha_gerada = res.data
                this.show_senha = true
            })
            .catch(err => {
                return err.response
            }).finally(()=> this.loading_gera_senha=false)
        },
        gerarSenha(objeto) {
            const chavesDesejadas = ['cd_paciente', 'nm_paciente'];
            const arrayValores = chavesDesejadas.map(chave => ''+objeto[chave]);
            this.loading_gera_senha = true
            api.create_etl('etl/query-post/?cod_query=post_gera_senha', arrayValores)
            .then(res => {
               this.getSenha(res.data)
            })
            .catch(err => {
                return err.response
            })
            .finally(()=>this.loading_gera_senha=false)
        },
        handleButtonClick(val) {
            this.tmp += val
            if(this.tmp.length <= 11){
                this.cpf = this.formatCPF(this.tmp)
            }else{
                return false
            }
        },
        handleClearIconClick() {
            this.cpf = ''; // Limpa o valor do 
            this.tmp = ''
        },
        handleBackspace() {
            if (this.tmp.length > 0) {
               this.tmp = this.tmp.slice(0, -1);
               this.cpf = this.tmp
            } 
        },
        formatCPF(cpf) {
            if (!cpf) return ''
            cpf = cpf.toString() // Converta para string
            cpf = cpf.replace(/\D/g, '') // Remove caracteres não numéricos do CPF
            cpf = cpf.replace(/^(\d{3})(\d)/, '$1.$2') // Formata XXX.
            cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3') // Formata XXX.XXX.
            cpf = cpf.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d{1,2})/, '$1.$2.$3-$4') // Formata XXX.XXX.XXX-XX
      
            return cpf
        },
        cpfRule(input) {
            return input?.length === 14 && this.isValidCPF(input)
        },
        isValidCPF(strCPF) {
            const getNextDigit = (lstValue, curValue, curIdx) =>
                lstValue + curValue * (9 - curIdx)
            let numValues = Array.from(strCPF.match(/\d/g), Number)

            let uniqueValues = new Set(numValues)
            let [, , ...arrCalc] = numValues.reverse()

            for (let i = 0; i < 2; i++) {
                arrCalc = [arrCalc.reduce(getNextDigit, 0) % 11 % 10, ...arrCalc]
            }

            return arrCalc.toString() === numValues.toString() && uniqueValues.size > 1
        },
        imprimirDiv(id) {
            const divToPrint = document.getElementById(`minhaDiv${id}`);

            // Crie um novo elemento <iframe>
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            // Abra o documento do iframe
            const doc = iframe.contentWindow.document;

            // Copie o conteúdo da div para o documento do iframe
            doc.open();
            doc.write(divToPrint.innerHTML);
            doc.close();

            // Defina a propriedade 'mozPrintCallback' no documento do iframe para imprimir silenciosamente
            doc.mozPrintCallback = function() {
                iframe.contentWindow.print();
            };

            // Chame a função print() no documento do iframe para imprimir silenciosamente
            iframe.contentWindow.print({silent:true});
            
            // Remova o iframe do documento
            document.body.removeChild(iframe);
        }
    },
    computed: {
        btn_cpfRule(){
            return this.cpf.length === 14 && this.isValidCPF(this.cpf)
        },
        btn_backspace(){
            return this.cpf.length > 0
        },
        offset(){ return this.agendamentos.length==0?'2':'0'},
        num_rows(){ return this.agendamentos.length==0?'8':'3'},
        centraliza(){ return this.agendamentos.length==0? 'ma-auto' : 'ma-auto'},
    },
    mounted() {
        // this.getAgendamentos()
        // Dados para o QR code
        
        // Gerar o QR code e atribuir à variável local qrcodeData
        
    },
    watch: {
        cpf(value) {
            this.showClearIcon = !!value; // Define se o ícone de limpar deve ser exibido
        },
    },
}

</script>
<style>
    
    .full-height {
        height: 100vh;
    }

    .imprimir-conteudo {
        /* Estilos padrão */
        font-size: 20px;
    }
    .ft40 {
        /* Estilos padrão */
        font-size: 40px;
        font-weight: bold;
    }

    @media print {
        .imprimir-conteudo {
        /* Estilos específicos para impressão */
        display: none;  /* Oculta o conteúdo durante a impressão */
        }
    }

</style>